// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".w8GXNY55eVjXgokr3Uke:last-child input{color:#757575 !important}.w8GXNY55eVjXgokr3Uke{gap:24px;display:flex;flex-direction:row}.w8GXNY55eVjXgokr3Uke input{width:100%;padding:12px 16px;font-size:16px;line-height:24px;height:48px;border-radius:4px;border:1px solid #000;border-color:#e6e3e3;margin-top:unset}.w8GXNY55eVjXgokr3Uke input:active,.w8GXNY55eVjXgokr3Uke input:focus{outline:none}.w8GXNY55eVjXgokr3Uke input.QeubmZo5by1vH4K6OldJ{border-color:#c0453a !important}.w8GXNY55eVjXgokr3Uke .jixH1Ed7nsNfYnXIL55u{color:#c0453a}.w8GXNY55eVjXgokr3Uke .PGac0c6EWkJy9GEJZYk5{flex:0 0 450px}.w8GXNY55eVjXgokr3Uke .SP6heogVeOcFJ3dwgR7L{flex:0 0 110px}.w8GXNY55eVjXgokr3Uke .XDiA8uR6uw2W0JOlxlv6{flex:0 0 18px;display:flex}.w8GXNY55eVjXgokr3Uke .XDiA8uR6uw2W0JOlxlv6::before{width:18px;height:18px;background-size:contain;display:inline-block;background-repeat:no-repeat;content:\"\";background-image:url(\"../assets/icons/Delete.svg\");margin-top:14px;cursor:pointer}.w8GXNY55eVjXgokr3Uke .XDiA8uR6uw2W0JOlxlv6.Q6_WKYieSgXvtUF4VnUx{pointer-events:none;opacity:.5}@media(max-width: 768.02px){.w8GXNY55eVjXgokr3Uke .PGac0c6EWkJy9GEJZYk5{flex:1 0 calc(75% - 48px)}.w8GXNY55eVjXgokr3Uke .SP6heogVeOcFJ3dwgR7L{flex:1 0 calc(25% - 24px)}.w8GXNY55eVjXgokr3Uke .XDiA8uR6uw2W0JOlxlv6{flex:0 0 calc(5% - 24px)}}@media only screen and (max-width: 400px){.w8GXNY55eVjXgokr3Uke{flex-direction:column;gap:8px;border-bottom:1px solid #000;border-color:#e6e3e3}.w8GXNY55eVjXgokr3Uke .PGac0c6EWkJy9GEJZYk5{flex:0 0 auto}.w8GXNY55eVjXgokr3Uke .SP6heogVeOcFJ3dwgR7L{flex:0 0 auto}.w8GXNY55eVjXgokr3Uke .XDiA8uR6uw2W0JOlxlv6{justify-content:center;min-height:48px;flex:0 0 auto}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableRow": "w8GXNY55eVjXgokr3Uke",
	"inputError": "QeubmZo5by1vH4K6OldJ",
	"errorMessage": "jixH1Ed7nsNfYnXIL55u",
	"firstColumn": "PGac0c6EWkJy9GEJZYk5",
	"secondColumn": "SP6heogVeOcFJ3dwgR7L",
	"trashCanColumn": "XDiA8uR6uw2W0JOlxlv6",
	"disabled": "Q6_WKYieSgXvtUF4VnUx"
};
export default ___CSS_LOADER_EXPORT___;
