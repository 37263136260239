import { useState } from "react";
import cn from 'classnames';
import styles from "./ProductEntry.module.scss";
import { normalizeNames } from "../../normalize";

type ValidatedField<T> = {
    value: T;
    error: string | null;
}

export type Entry = {
    id: number;
    articleNumber: ValidatedField<string>;
    quantity: ValidatedField<number>;
    error: string | null;
}

export type ExcelResponse = {
    quickOrderEntries: Entry[];
    excelValidationMessage: string;
}

type ProductEntryProps = {
    entry: Entry;
    onDataChange: (e: Entry) => void;
    onNameChange: (() => void) | null;
    onRemove: () => void;
    articleNoPlaceholder: string;
}

function ProductEntry({ entry, onDataChange, onNameChange, onRemove, articleNoPlaceholder }: ProductEntryProps) {

    const [name, setName] = useState(entry.articleNumber.value);
    const [quantity, setQuantity] = useState(entry.quantity.value);

    const updateEntry = async () => {
        if (!name) {
            return;
        }

        const newName = (await normalizeNames([name], setName))[0];

        const response = await fetch('/api/quick-order/validate', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Swecon-Current-Language": window.app.preloadState.currentLanguage
            },
            body: JSON.stringify([{
                id: entry.id,
                articleNumber: { value: newName, error: null },
                quantity: { value: quantity, error: null },
                error: null,
            }]),
        });

        if (!response.ok) {
            //maybe show a message?
            console.error(await response.text());
            return;
        }

        onDataChange((await response.json())[0]);
    }

    return <div className={styles.tableRow}>
        <div className={styles.firstColumn}>
            <input placeholder={articleNoPlaceholder} value={name} onChange={(ev) => { setName(ev.target.value ?? ""); onNameChange?.() }} onBlur={updateEntry} className={cn(styles.articleNumberInput, (entry.articleNumber.error || entry.error) && styles.inputError)}>
            </input>
            {(entry.articleNumber.error || entry.error) && <div className={styles.errorMessage}>{entry.articleNumber.error} {entry.error}</div>}
        </div>
        <div className={styles.secondColumn}>
            <input type="number" value={quantity} onChange={ev => setQuantity(ev.target.valueAsNumber ?? 1)} onBlur={updateEntry} className={cn(styles.quantityInput, entry.quantity.error && styles.inputError)}>
            </input>
            {entry.quantity.error && <div className={styles.errorMessage}>{entry.quantity.error}</div>}
        </div>
        <div className={cn(styles.trashCanColumn, onNameChange != null && styles.disabled)} onClick={onRemove}></div>
    </div>
}

export default ProductEntry;
